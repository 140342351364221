import React from 'react';
import { devices as devicesClient } from '../../ajax_clients';
import SearchBar from "../search/search_bar";
import { bindMethods, dig } from "../../shared/functions";
import pagedTableLayout from "../paged_table/layout";

const getAjaxClient = ({ deviceId }) => {
    const client = {}
    client.read = (options) => devicesClient.readGroupsForAssignments(deviceId, options)
    client.createGroupAssignment = (groupId) => devicesClient.createGroupAssignment(deviceId, groupId)
    client.removeGroupAssignment = (groupId) => devicesClient.removeGroupAssignments(deviceId, [groupId])
    client.readGroupAssignments = () => devicesClient.readGroupAssignments(deviceId, { types: ['static'] })
    return client
};

class AssignGroupsPagedTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            assignedGroups: {},
            loading: false
        }
        bindMethods(this)
    }

    componentDidMount() {
        this.loadAssignedGroups()
    }

    renderSearchBar() {
        return <div className="search-controls">
            <SearchBar
                timeout={ 600 }
                searching={ this.props.searching }
                initialValue={ this.props.query.search }
                placeholderText='Filter by name'
                onSearchChange={ this.onSearchChange }/>
        </div>
    }

    onSearchChange(searchRegex, search) {
        if (search !== this.props.query.search) {
            this.props.setQuery({ search, page: 1 })
        }
    }

    renderTopButtons() {
        return <div id="top-buttons">
            <a className="btn btn-default back-button"
               href={ AdminRoutes.adminDevicePath(this.props.deviceId, { anchor: 'groups', format: null }) }>
                <i className="glyphicon glyphicon-chevron-left"/> Back
            </a>
        </div>
    }

    loadAssignedGroups() {
        this.setState({ loading: true }, () => {
            this.props.ajaxClient.readGroupAssignments().then(({ data }) => {
                const assignedGroups = data.reduce((obj, group) => {
                    obj[group.id] = group
                    return obj
                }, {})

                this.setState({ assignedGroups, loading: false });
            }).catch(() => {
                this.setState({ loading: false });
            })
        })
    }

    isAssigned(group) {
        return !!this.state.assignedGroups[group.id]
    }

    assignGroup(group) {
        this.setState({ loading: true }, () => {
            this.props.ajaxClient.createGroupAssignment(group.id).then(this.loadAssignedGroups)
        })
    }

    unassignGroup(group) {
        this.setState({ loading: true }, () => {
            this.props.ajaxClient.removeGroupAssignment(group.id).then(this.loadAssignedGroups)
        })
    }

    renderAssignmentButton(group) {
        const buttonClasses = ["btn", "btn-default", "btn-sm", "pull-right"]
        const loadingGroup = this.state.loading === true
        if (this.isAssigned(group)) {
            buttonClasses.push("unassign-group")
            const remove = (e) => {
                e.preventDefault()
                this.unassignGroup(group)
            };

            return <a key={ `${ group.id }-unassign` } href="#unassign"
                      onClick={ remove }
                      className={ buttonClasses.join(" ") }
                      disabled={ loadingGroup }>Unassign</a>

        } else {
            const disabled = loadingGroup
            const add = (e) => {
                e.preventDefault()
                this.assignGroup(group)
            };

            if (disabled) {
                return <a
                    key={ `${ group.id }-disabled` }
                    className={ buttonClasses.join(" ") }
                    disabled={ disabled }
                    onClick={ e => e.preventDefault() }
                >Assign</a>
            } else {
                return <a
                    key={ `${ group.id }-enabled` }
                    href="#assign"
                    onClick={ add }
                    className={ buttonClasses.join(" ") }>Assign</a>
            }
        }
    }

    pagedTableProps() {
        const headers = [
            { displayName: "Name", key: "name", show: true },
            { displayName: "", key: "addButton", show: true },
        ]

        return {
            headers: headers.filter(header => header.show),
            showCheck: false,
            tableClasses: ["table", "hover", "table-striped", "table-hover"],
            renderRow: (row) => ({
                ...row,
                addButton: this.renderAssignmentButton(row),
                // name: <a href={ row.originalRecord }>{ truncate(row.name, 50) }</a>,
                name: row.name,
            }),
        }
    }

    render() {
        const actions = <a href="/admin/v2/groups/new" className="btn btn-default pull-right">Create
            Group</a>

        let notFoundMessage
        const search = dig(['props', 'query', 'search'], this)
        if (search) {
            notFoundMessage = "No groups match this search."
        } else {
            notFoundMessage = "No groups have been created. Create a group."
        }

        const notFound = (
            <div className="centered alert alert-info" role="alert">
                { notFoundMessage }
            </div>
        )

        return (
            <React.Fragment>
                <h1>
                    <span className="glyphicon glyphicon-duplicate configs-icon"/>
                    Assign Group <small>{ this.props.deviceName }</small>
                </h1>

                { this.renderTopButtons() }
                { this.renderSearchBar() }

                <div className="well">
                    <div id="assign-groups-table">
                        { this.props.renderTopSection(null, actions) }
                        { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(AssignGroupsPagedTable, getAjaxClient, null, null);
