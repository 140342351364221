export const devices = {
    read(options) {
        return $.getJSON(AdminRoutes.adminDevicesPath(options));
    },

    destroyMultiple(ids) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.destroyMultipleAdminDevicesPath(),
            data: JSON.stringify({ ids })
        });
    },

    restart(id, notifyUser = false, rebuildKernelCache = false) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.restartAdminDevicePath(id),
            data: JSON.stringify({ notifyUser, rebuildKernelCache })
        });
    },
    shutdown(ids) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.shutdownAdminDevicesPath(),
            data: JSON.stringify({ ids })
        });
    },
    // pushMessagesNewMultipleAdminDevicesPath
    sendMessage(ids, message) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.pushMessagesMultipleAdminDevicesPath(),
            data: JSON.stringify({ admin_push_message: { ids, message } })
        });
    },

    assignToGroup(ids, groupId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.multipleAssignGroupAdminDevicesPath(),
            data: JSON.stringify({ ids, group_id: groupId })
        });
    },

    pushOSUpdate(id, options) {
        options = options || {}
        return $.ajax({
            method: 'PUT',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.adminDeviceOSUpdatePath(id),
            data: JSON.stringify(options)
        });
    },

    readProfileAssignments(id) {
        return $.getJSON(AdminRoutes.adminDeviceProfileAssignmentsPath(id));
    },

    assignProfile(id, profileId) {
        return $.ajax({
            method: 'POST',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.adminDeviceProfileAssignmentsPath(id),
            data: JSON.stringify({ profileId })
        });
    },

    unassignProfile(id, profileId) {
        return $.ajax({
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            url: AdminRoutes.adminDeviceProfileAssignmentPath(id, profileId),
        });
    },

    enableRemoteDesktop(id) {
        return $.post({
            method: 'POST',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminDeviceRemoteDesktopPath(id)
        })
    },

    disableRemoteDesktop(id) {
        return $.post({
            method: 'DELETE',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminDeviceRemoteDesktopPath(id)
        })
    },

    updateTimeZones(deviceId, timeZone, options = {}) {
        return $.post({
            method: 'PUT',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminDeviceTimeZonePath(deviceId),
            data: { ...options, time_zone: timeZone }
        })
    },

    readGroupAssignments(deviceId, options) {
        return $.getJSON(AdminRoutes.adminDeviceGroupAssignmentsPath(deviceId), options);
    },

    readGroupsForAssignments(deviceId, options) {
        return $.getJSON(AdminRoutes.groupsAdminDeviceGroupAssignmentsPath(deviceId), options);
    },

    createGroupAssignment(deviceId, groupId) {
        return $.post({
            method: 'POST',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminDeviceGroupAssignmentsPath(deviceId),
            data: { groupId }
        })
    },

    removeGroupAssignments(deviceId, groupIds) {
        return $.ajax({
            url: AdminRoutes.adminDeviceGroupAssignmentsPath(deviceId),
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            data: JSON.stringify({ groupIds }),
        });
    }

};
