import React from 'react';
import { deletedDevices as ajaxClient, groups as groupsClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { SearchBar } from "../search";
import { bindMethods, truncate } from "../../shared/functions";
import { visitURL } from "../../shared/query";
import { v2Route } from '../../shared/functions';

const getAjaxClient = () => ajaxClient;
const initialQuery  = { page: 1, per: 20, search: null, order: 'serialNumber', order_d: 'desc' };

const renderGroupSelect = (groups, selected, profile, onChooseGroup) => {
    let options = []
    options.push(<option key="all" value="">All Device Groups</option>)

    groups.forEach(group => {
        const { name, id } = group
        options.push(<option key={ id } value={ id }>{ name }</option>)
    })
    options.push(<option key="spacer" value="" disabled={ true }>-----------</option>)
    options.push(<option key="awaiting-enrollment" value="awaiting-enrollment">Awaiting Enrollment</option>)
    options.push(<option key="deleted-devices" value="deleted-devices">Deleted Devices</option>)

    if (profile) {
        options.push(<option key="profile-filter"
                             value={ `profile-${ profile.id }` }>{ `Profile: ${ truncate(profile.name, 35) }` }</option>)
    }
    return (
        <select value={ selected } onChange={ onChooseGroup } className="form-control filter">
            { options }
        </select>
    );

}

class DeletedDevicesPagedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: []
        }
        bindMethods(this)
    }

    componentDidMount() {
        groupsClient.read({ per: 'all' }).then(({ data }) => {
            this.setState({ groups: data })
        })
    }

    onSearchChange(searchRegex, search) {
        if (search !== this.props.query.search) {
            this.props.setQuery({
                search,
                page: 1
            })
        }
    }

    onChooseGroup(e) {
        const groupId = e.target.value;
        if (groupId !== 'deleted-devices') {
            const search = this.props.query.search
            visitURL(v2Route('devices', { search: search ?? "", group: groupId }))
        }
    }

    renderGroupSelect() {
        return renderGroupSelect(this.state.groups, 'deleted-devices', null, this.onChooseGroup);
    }

    renderSearchBar() {
        return <SearchBar
            searchOnEnter={ true }
            timeout={ 600 }
            searching={ this.props.searching }
            key={ 'search' }
            initialValue={ this.props.query.search }
            placeholderText='Search by serial number'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;
    }

    pagedTableProps() {
        return {
            headers:   [
                { displayName: "Serial Number", key: "serialNumber", orderable: true },
                { displayName: "Name", key: "name" },
                { displayName: "Last Deleted", key: "deletedAt", orderable: true },
            ],
            showCheck: false,
            renderRow: (row) => {
                const serialNumber = <a href={ AdminRoutes.adminDeletedDevicePath(row.serialNumber, {format: null}) }>
                    { row.serialNumber }
                </a>
                return { ...row, serialNumber }
            }
        }
    }

    render() {

        const notFound = <div className="alert alert-warning centered results-message" role="alert">
            No Deleted Devices found
        </div>

        return (
            <React.Fragment>
                <div className="special-container">
                    <h1><span className="glyphicon glyphicon-phone devices-icon"/>Deleted Devices</h1>
                </div>
                <div className="devices">
                    <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                        { this.renderGroupSelect() }
                        { this.renderSearchBar() }
                    </div>
                    { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                </div>
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(DeletedDevicesPagedTable, getAjaxClient, initialQuery);