import React from 'react';
import {
    profiles as profilesClient,
    devices as devicesClient,
    groups as groupsClient
} from '../../ajax_clients';
import SearchBar from "../search/search_bar";
import { bindMethods, dig, truncate } from "../../shared/functions";
import pagedTableLayout from "../paged_table/layout";
import { requirementColumns } from "./profile_utilities";
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const getAjaxClient = ({ assignableObject }) => {
    const client = {}
    const { type, id } = assignableObject;
    let objectClient
    if (type === 'group') {
        objectClient = groupsClient;
        client.read = (options) => profilesClient.readForGroup(id, options)
    } else {
        objectClient = devicesClient
        client.read = (options) => profilesClient.readForDevice(id, options)
    }

    client.assignProfile = (profileId) => objectClient.assignProfile(id, profileId)
    client.unassignProfile = (profileId) => objectClient.unassignProfile(id, profileId)
    client.readProfileAssignments = () => objectClient.readProfileAssignments(id)
    return client
};

class AssignProfilesPagedTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            assignedProfiles: {},
            profileTypes: {},
            data: props.data || [],
            loading: false
        }
        bindMethods(this)
    }

    componentDidMount() {
        this.loadAssignedProfiles()
    }

    renderSearchBar() {
        return <div className="search-controls">
            <SearchBar
                timeout={ 600 }
                searching={ this.props.searching }
                initialValue={ this.props.query.search }
                placeholderText='Filter by name or type'
                onSearchChange={ this.onSearchChange }/>
        </div>
    }

    onSearchChange(searchRegex, search) {
        if (search != this.props.query.search) {
            this.props.setQuery({ search, page: 1 })
        }
    }

    renderTopButtons() {
        const anchor = { anchor: 'profiles', format: null };
        const objectPath = this.props.assignableObject.type === 'device' ? AdminRoutes.adminDevicePath(this.props.assignableObject.id, anchor) :
            AdminRoutes.adminGroupPath(this.props.assignableObject.id, anchor)
        return <div id="top-buttons">
            <a className="btn btn-default back-button" href={ objectPath }>
                <i className="glyphicon glyphicon-chevron-left"/> Back
            </a>
        </div>
    }

    loadAssignedProfiles() {
        this.setState({ loading: true }, () => {
            this.props.ajaxClient.readProfileAssignments().then(({ data }) => {
                const assignedProfiles = {}

                data.forEach(profileType => {
                    this.updateProfileType(profileType)

                    let profiles = profileType.profilesToInstall
                        .concat(profileType.profilesToSkip)
                        .filter(profile => profile.directAssignment)

                    profiles.forEach(profile => {
                        assignedProfiles[profile.id] = profile
                    })
                });

                this.setState({ assignedProfiles, loading: false });
            })
        })
    }

    updateProfileType(profileType) {
        profileType.hasDirectAssignment = profileType.profilesToInstall.some(profile => profile.directAssignment);
        profileType.hasSingularInstallation = profileType.hasDirectAssignment && profileType.singletonProfile
        let profileTypes = { ...this.state.profileTypes, [profileType.type]: profileType }
        this.setState({ profileTypes })
    }

    isAssigned(profile) {
        return !!this.state.assignedProfiles[profile.id]
    }

    assignProfile(profile) {
        this.setState({ loading: profile.type }, () => {
            this.props.ajaxClient.assignProfile(profile.id)
                .then(({ data }) => {
                    this.updateProfileType(data)
                    this.setState((state) => ({
                        assignedProfiles: {
                            ...state.assignedProfiles,
                            [profile.id]: profile
                        },
                        loading: false
                    }))
                })
                .catch(this.loadAssignedProfiles)
        })
    }

    unassignProfile(profile) {
        this.setState({ loading: profile.type }, () => {
            this.props.ajaxClient.unassignProfile(profile.id)
                .then(({ data }) => {
                    this.updateProfileType(data)
                    this.setState((state) => {
                        const { [profile.id]: _removed, ...assignedProfiles } = state.assignedProfiles;
                        return { assignedProfiles, loading: false }
                    })
                })
                .catch(this.loadAssignedProfiles)
        })
    }

    renderAssignmentButton(profile) {
        const buttonClasses = ["btn", "btn-default", "btn-sm", "pull-right"]
        const profileType = this.state.profileTypes[profile.type]
        const loadingProfile = this.state.loading === true || (this.state.loading === profile.type && profileType?.singletonProfile);
        if (this.isAssigned(profile)) {
            buttonClasses.push("unassign-profile")
            const remove = (e) => {
                e.preventDefault()
                this.unassignProfile(profile)
            };

            return <a key={ `${ profile.id }-unassign` } href="#unassign"
                      onClick={ remove }
                      className={ buttonClasses.join(" ") }
                      disabled={ loadingProfile }>Unassign</a>

        } else {
            let incompatibleProfile, notInstallableToDevice;
            if (this.props.assignableObject.type === 'device') {
                notInstallableToDevice = !profile.installableToInstance && this.props.assignableObject.type === 'device';
                incompatibleProfile = !profile.installableTo || notInstallableToDevice;
            } else {
                incompatibleProfile = false
            }
            const hasSingularInstallation = profileType?.hasSingularInstallation
            const disabled = loadingProfile || incompatibleProfile || hasSingularInstallation
            let add, disabledText;
            if (disabled) {
                if (hasSingularInstallation) {
                    disabledText = "Devices only support a single profile of this type and one has already been assigned."
                } else if (notInstallableToDevice) {
                    disabledText = "Profile scope excludes this device."
                } else {
                    disabledText = "Profile is not compatible with this device."
                }
                const element = <a
                    className={ buttonClasses.join(" ") }
                    disabled={ disabled }
                    onClick={ e => e.preventDefault() }
                >Assign</a>

                if (this.state.loading) {
                    return element
                } else {
                    return <OverlayTrigger
                        placement="left"
                        overlay={ <Tooltip id={ profile.id }>{ disabledText }</Tooltip> }>
                        { element }
                    </OverlayTrigger>
                }

            } else {
                add = (e) => {
                    e.preventDefault()
                    this.assignProfile(profile)
                };
                return <a
                    key={ `${ profile.id }-enabled` }
                    href="#assign"
                    onClick={ add }
                    className={ buttonClasses.join(" ") }>Assign</a>
            }

        }
    }

    pagedTableProps() {
        const headers = [
            { displayName: "Name", key: "name", show: true },
            { displayName: "Type", key: "displayName", show: true },
            { displayName: "Declarative Enabled", key: "declarative", show: this.props.assignableObject.declarative },
            { displayName: "macOS", key: "macos", show: true },
            { displayName: "iOS", key: "ios", show: true },
            { displayName: "tvOS", key: "tvos", show: true },
            { displayName: "", key: "addButton", show: true },
        ]

        return {
            headers: headers.filter(header => header.show),
            showCheck: false,
            tableClasses: ["table", "hover", "table-striped", "table-hover"],
            renderRow: (row) => ({
                ...row,
                ...requirementColumns(row),
                addButton: this.renderAssignmentButton(row),
                declarative: row.declarative ? 'Yes' : 'No',
                name: <a href={ row.originalRecord }>{ truncate(row.name, 50) }</a>,
                test: true,
            }),
        }
    }

    renderCreateProfileButton() {
        let href;
        if (this.props.assignableObject.type === 'device') {
            href = AdminRoutes.newAdminDeviceProfilePath(this.props.assignableObject.id, { format: null })
        } else {
            href = AdminRoutes.newAdminGroupProfilePath(this.props.assignableObject.id, { format: null })
        }

        return <a href={ href } className="btn btn-default pull-right">Create Profile</a>;
    }

    render() {
        const actions = this.renderCreateProfileButton()

        let notFoundMessage
        const search = dig(['props', 'query', 'search'], this)
        if (search) {
            notFoundMessage = "No profiles match this search."
        } else {
            notFoundMessage = "No profiles have been created. Create a profile."
        }

        const notFound = (
            <div className="centered alert alert-info" role="alert">
                { notFoundMessage }
            </div>
        )

        return (
            <React.Fragment>
                <h1>
                    <span className="glyphicon glyphicon-duplicate configs-icon"/>
                    Assign Profile <small>{ this.props.assignableObject.name }</small>
                </h1>

                { this.renderTopButtons() }
                { this.renderSearchBar() }

                <div className="well">
                    <div id="assign-profiles-table">
                        { this.props.renderTopSection(null, actions) }
                        { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(AssignProfilesPagedTable, getAjaxClient, null, null);
