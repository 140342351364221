import React, { useCallback, useEffect, useState } from "react"
import { devices as ajaxClient } from '../../ajax_clients/devices'
import { timeZones as timeZonesClient } from '../../ajax_clients'
import FormGroup from "../forms/form_group"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { visitURL } from "../../shared/query";

export default function SetTimeZone(props) {
  const { deviceId, timeZone } = props
  const [timeZoneOptions, setTimeZoneOptions] = useState([])
  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZone)

  useEffect(() => {
    timeZonesClient.read().then((timeZones) => {
      setTimeZoneOptions(timeZones.map((tz) => ({ label: tz.name, value: tz.value })))
    })
  }, []);

  const goBack = useCallback((e) => {
    e.preventDefault()
    history.back()
  }, [])

  const onSubmit = useCallback((e) => {
    e.preventDefault()

    ajaxClient.updateTimeZones([deviceId], selectedTimeZone, { flash: 'later' }).then(() => {
        visitURL(AdminRoutes.adminDevicePath(deviceId, { format: null }))
    }).catch(({ responseJSON }) => {
      const { errors } = responseJSON
      errors.forEach(({ title }) => window.addFlashAlert('error', title))
    })
  }, [selectedTimeZone])

  if (timeZoneOptions.length === 0) return null

  return (
    <div className="set-time-zone multi-select-panel">
      <h1>Set Time Zone</h1>

      <div className="well">
        <p>
          This will update the time zone on the selected devices.
        </p>
        <form>
          <FormGroup title="Time Zone" inputClasses={['form-control', 'p-0']}>
            <Autocomplete
              disableClearable
              isOptionEqualToValue={(option, value) => option.value === value}
              onChange={(_e, value) => setSelectedTimeZone(value.value)}
              options={timeZoneOptions}
              value={selectedTimeZone}
              renderInput={(params) => (
                <TextField {...params} variant="standard" sx={{ ".MuiInput-root ": { "padding-left": "16px" }}} />
              )}
            />
          </FormGroup>

          <div className="buttons">
            <a href="#cancel" className="btn btn-default" onClick={goBack}>Cancel</a>
            <a href="#submit" className="btn btn-primary" onClick={onSubmit}>Submit</a>
          </div>
        </form>
      </div>
    </div>
  )
}
