import React from 'react';

import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds, truncate } from '../../shared/functions';

import { devices as devicesClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";

const getAjaxClient = ({ deviceId }) => ({
    read() {
        return devicesClient.readGroupAssignments(deviceId).then(response => {
            const data = response.data.map(group => {
                return { ...group, checkDisabled: group.type !== "static" };
            });
            return { data:  data }
        })
    },
    destroyMultiple: (ids) => devicesClient.removeGroupAssignments(deviceId, ids)

});

class GroupsPagedTable extends React.Component {

    constructor(props) {
        super({ ...props });
        this.state = {
            errors: [],
        };

        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#unassign",
                action: this.unassignGroups,
                text:   "Unassign",
                policy: 'update'
            }
        ];

        return <ActionDropDown
            text="Actions"
            items={ items }
            policy={ this.props.policy }
            disabled={ !this.props.hasCheckedItems() }
        />
    }

    unassignGroups(e) {
        e.preventDefault()
        if (confirm('This will unassign the selected groups from the device. Are you sure?')) {
            callWithIds(this.props.checkedItems(), this.props.ajaxClient.destroyMultiple).then(this.props.readData())
        }
    }

    pagedTableProps() {
        const headers = [
            { displayName: "Name", key: "name" }
        ];
        if (this.props.policy.v2Groups) {
            headers.push({ displayName: "Type", key: "type" },)
        }

        return {
            headers:   headers,
            showCheck: this.props.policy.update === true,
            renderRow: (row) => {
                const name = <a href={ `/admin/v2/groups/${row.id}` }>{ truncate(row.name, 50)  }</a>
                return { ...row, name }
            },
        }
    }

    render() {
        let actions = <React.Fragment>
            { this.renderActionButton() }
            <a className="btn btn-default pull-right" href={ AdminRoutes.adminDeviceGroupAssignmentsPath(this.props.deviceId, { format: null }) }>Assign Group</a>
        </React.Fragment>;

        const notFound = (
            <div className="centered alert alert-info" role="alert">
            No groups are currently assigned. Visit the <a href={ AdminRoutes.adminDeviceGroupAssignmentsPath(this.props.deviceId, { format: null }) }>Assign Groups</a> page to assign a new group.
            </div>
        );

        return (
            <div id="profile-assignments-table">
                { this.props.renderTopSection(null, actions) }
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(GroupsPagedTable, getAjaxClient, undefined, null);