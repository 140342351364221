export const enrollmentCodes = {
    read(options) {
        return $.getJSON(AdminRoutes.adminEnrollmentCodesPath(options));
    },

    destroyMultiple(ids) {
        return $.ajax({
            method:   'POST',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.destroyMultipleAdminEnrollmentCodesPath(),
            data:     JSON.stringify({ ids })
        });
    },

    enableRemoteDesktop(ids) {
        return $.ajax({
            method:   'PATCH',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.enableRemoteDesktopAdminEnrollmentCodesPath(),
            data:     JSON.stringify({ ids })
        });
    },
    disableRemoteDesktop(ids) {
        return $.ajax({
            method:   'PATCH',
            dataType: 'json',
            contentType: "application/json",
            url:      AdminRoutes.disableRemoteDesktopAdminEnrollmentCodesPath(),
            data:     JSON.stringify({ ids })
        });
    },

    readGroupAssignments(enrollmentCodeId, options) {
        return $.getJSON(AdminRoutes.adminEnrollmentCodeGroupAssignmentsPath(enrollmentCodeId), options);
    },

    readGroupsForAssignments(enrollmentCodeId, options) {
        return $.getJSON(AdminRoutes.groupsAdminEnrollmentCodeGroupAssignmentsPath(enrollmentCodeId), options);
    },

    createGroupAssignment(enrollmentCodeId, groupId) {
        return $.post({
            method: 'POST',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminEnrollmentCodeGroupAssignmentsPath(enrollmentCodeId),
            data: { groupId }
        })
    },

    removeGroupAssignments(enrollmentCodeId, groupIds) {
        return $.ajax({
            url: AdminRoutes.adminEnrollmentCodeGroupAssignmentsPath(enrollmentCodeId),
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            data: JSON.stringify({ groupIds }),
        });
    }
};