import React from 'react';
import { logs as logsClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { SearchBar } from "../search";
import { bindMethods, v2Route } from "../../shared/functions";

const getAjaxClient = () => logsClient;
const initialQuery  = { search: null, order: 'at', order_d: 'desc', page: 1, per: 20, log_filter: 'all' };

class LogsPagedTable extends React.Component {
    constructor(props) {
        super(props);
        bindMethods(this);
    }

    onSearchChange(searchRegex, search) {
        if (search != this.props.query.search) {
            this.props.setQuery({ search, page: 1 })
        }
    }

    onChooseLogFilter(e) {
        this.props.setQuery({
            log_filter: e.target.value,
            page:       1
        });
    }

    renderLogFilter() {
        return (
            <select onChange={ this.onChooseLogFilter }
                    value={ this.props.query.log_filter }
                    className="form-control filter">
                <option value="all">All Logs</option>
                <option value="admin">Admin Logs</option>
                <option value="device-all">Device Logs - All</option>
                <option value="device-error">Device Logs - Errors</option>
            </select>
        );
    }

    renderSearchBar() {
        return <SearchBar
            timeout={ 600 }
            searching={ this.props.searching }
            key={ this.props.query.device_id }
            initialValue={ this.props.query.search }
            placeholderText='Filter by device, event type, or email address. Only exact matches will be returned.'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;
    }

    renderId(log) {
        return (
            <a href={ AdminRoutes.adminLogPath(log.id, {format: null}) } title={ log.id }>{ log.shortUuid }</a>
        )
    }

    renderDevice({ device }) {
        const query = device.serialNumber == null ? {
            search: device.udid,
            searchAttribute: "udid",
        } : {
            search: device.serialNumber,
            searchAttribute: "serialNumber",
        }

        return <a
                href={ v2Route("devices", query) }>{ query.search }</a>
    }

    renderMetadata({ metadata }) {
        if (Object.entries(metadata).length === 0) {
            return '';
        } else {
            return <div>{ JSON.stringify(metadata, null, 2) }</div>;
        }
    }

    pagedTableProps() {
        return {
            headers:   this.headers(),
            showCheck: false,
            renderRow: (log) => Object.assign({}, log, {
                id:       this.renderId(log),
                device:   this.renderDevice(log),
                metadata: this.renderMetadata(log)
            })
        }
    }

    headers() {
        const headers = [
            { displayName: "ID", key: "id" },
            { displayName: "At", key: "at", orderable: true },
            { displayName: "Namespace", key: "namespace" },
            { displayName: "Type", key: "eventType" },
            { displayName: "Level", key: "level" },
            { displayName: "Device Ref", key: "device" },
            { displayName: "Requested By", key: "requestedBy" },
            { displayName: "Metadata", key: "metadata" },
        ];

        let matchingHeaders;
        switch (this.props.query.log_filter) {
            case "admin":
                matchingHeaders = ["ID", "At", "Type", "Device Ref", "Requested By", "Metadata"];
                break;
            case "device-all":
            case "device-error":
                matchingHeaders = ["ID", "At", "Type", "Level", "Device Ref", "Metadata"];
                break;
            default:
                return headers;
        }
        return headers.filter(header => matchingHeaders.includes(header.displayName))
    }

    render() {
        const notFound = <div className="alert alert-warning centered results-message" role="alert">no logs found</div>
        return (
            <div className="logs standalone">
                <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                    { this.renderLogFilter() }
                    { this.renderSearchBar() }
                </div>
                { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
            </div>
        )
    }
}

export default pagedTableLayout(LogsPagedTable, getAjaxClient, initialQuery);