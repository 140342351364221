import React, { useState, useMemo, useCallback, useEffect } from 'react'

import MuiModal from '../shared/mui_modal';
import {
  FormControl,
  Grid,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material"
import { deploymentTypeLabels, installTypeLabels } from './groups_paged_table';
import { apps as appsClient } from '../../ajax_clients';
import allSettled from "promise.allsettled";

const installTypes = {
  standard: ['auto', 'manual'],
  munki: ['managed', 'self_serve', 'default_installs', 'managed_updates'],
}

export default function AssignGroupsModal({ show, onClose, onSuccess, appId }) {
  const [app, setApp] = useState({})
  const [groups, setGroups] = useState([])
  const [selectedGroupIds, setSelectedGroupIds] = useState([])
  const [deploymentType, setDeploymentType] = useState('')
  const [installType, setInstallType] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!show) return

    setLoading(true)
    const loadApp = appsClient.read(appId)
    const loadGroups = appsClient.readGroupsForAssignments(appId)

    allSettled([loadApp, loadGroups]).then((responses) => {
      const [appResponse, groupsResponse] = responses
      const loadedApp = appResponse.value.data
      setApp(loadedApp)
      setGroups(groupsResponse.value.data)
      setDeploymentType(loadedApp.installationChannels[0])
      setInstallType(installTypes[loadedApp.installationChannels[0]][0])
      setLoading(false)
    })
  }, [appId, show, setLoading, setApp, setGroups, setDeploymentType, setInstallType])


  const groupOptions = useMemo(() => {
    if (loading) return []

    return groups.map(group => ({
      id: group.id,
      label: group.name,
    }))
  }, [groups, loading])

  const handleChange = useCallback((_event, newValue) => {
    if (!newValue) {
      setSelectedGroupIds([])
      return
    }

    setSelectedGroupIds(newValue.map(group => group.id))
  }
  , [setSelectedGroupIds])

  const handleDeploymentTypeChange = useCallback((event) => {
    setDeploymentType(event.target.value)
    setInstallType(installTypes[event.target.value][0])
  }, [setDeploymentType, setInstallType])

  const handleInstallTypeChange = useCallback((event) => {
    setInstallType(event.target.value)
  }, [setInstallType])

  const optionsForInstallType = useMemo(() => {
    if (!deploymentType) return []

    return installTypes[deploymentType].map(type => ({
      value: type,
      label: installTypeLabels[type],
    }))
  }, [deploymentType])

  const optionsForDeploymentType = useMemo(() => {
    if (!deploymentType) return []
    if (!app) return []

    return app?.installationChannels?.map(channel => ({
      value: channel,
      label: deploymentTypeLabels[channel],
    })) || []
  }, [app, deploymentType])

  const handleCommit = useCallback(() => {
    setLoading(true)
    appsClient.createGroupAssignment(appId, selectedGroupIds, deploymentType, installType).then(() => {
      setSelectedGroupIds([])
      setDeploymentType('')
      setInstallType('')
      onSuccess()
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }, [appId, selectedGroupIds, deploymentType, installType, setLoading, onClose])

  if (!show) return null;

  return (
    <MuiModal
      titleText="Assign Groups"
      buttonText="Assign"
      onClose={onClose}
      onCommit={handleCommit}
    >
      <Grid
          item
          xs={12}
          container
          alignItems="center"
          spacing={2}
          sx={{ minHeight: "120px" }}
        >
           <>
            <Grid item xs={2}>
              <InputLabel id="deployment-type-select-label">Install Method</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth size="small">
                <Select
                  labelId="deployment-type-select-label"
                  variant="standard"
                  value={deploymentType}
                  label="Install Method"
                  onChange={handleDeploymentTypeChange}
                  disabled={loading}
                >
                  {optionsForDeploymentType.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="install-type-select-label">Install Type</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth size="small">
                <Select
                  labelId="install-type-select-label"
                  variant="standard"
                  value={installType}
                  label="Install Method"
                  onChange={handleInstallTypeChange}
                  disabled={loading}
                >
                  {optionsForInstallType.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="group-select-label">Groups</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="group-select"
                  aria-labelledby="group-select-label"
                  options={groupOptions}
                  getOptionLabel={option => option.label}
                  disableCloseOnSelect
                  loading={loading}
                  onChange={handleChange}
                  disabled={loading}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select groups"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        </Grid>
    </MuiModal>
  );
}