import React from 'react';

import pagedTableLayout from '../paged_table/layout';
import { bindMethods, callWithIds, truncate } from '../../shared/functions';

import { apps as appsClient } from '../../ajax_clients';
import ActionDropDown from "../shared/action_drop_down";
import AssignGroupsModal from './assign_groups_modal';

const getAjaxClient = ({ appId }) => ({
    read: () => appsClient.readGroupAssignments(appId),
    destroyMultiple: (ids) => appsClient.removeGroupAssignments(appId, ids)
});

export const installTypeLabels = {
    auto: 'Auto',
    manual: 'Manual',
    managed: 'Managed',
    self_serve: 'Self Serve',
    default_installs: 'Default Installs',
    managed_updates: 'Managed Updates',
}

export const deploymentTypeLabels = {
    standard: 'MDM',
    munki: 'Munki',
}

class GroupsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            showModal: false,
        };

        bindMethods(this);
    }

    renderActionButton() {
        const items = [
            {
                href:   "#unassign",
                action: this.unassignGroups,
                text:   "Unassign",
                policy: 'update'
            }
        ];

        return <ActionDropDown
            text="Actions"
            items={ items }
            policy={ this.props.policy }
            disabled={ !this.props.hasCheckedItems() }
        />
    }

    unassignGroups(e) {
        e.preventDefault()
        if (confirm('This will unassign the selected groups from the app. Are you sure?')) {
            callWithIds(this.props.checkedItems(), this.props.ajaxClient.destroyMultiple).then(() => {
                this.setState({ loaded: false }) // ensure that a reload is triggered regardless of the current state
                this.props.readData()
            })
        }
    }

    assignGroups(e) {
        e.preventDefault()
        this.setState({ showModal: true })
    }

    pagedTableProps() {
        const headers = [
            { displayName: "Name", key: "name" },
            { displayName: "Install Method", key: "deploymentType" },
            { displayName: "Install Type", key: "installType" },
            { displayName: "Group Type", key: "shortType" },
        ];

        return {
            headers:   headers,
            showCheck: this.props.policy.update === true,
            renderRow: (row) => {
                const name = <a href={ `/admin/v2/groups/${row.id}` }>{ truncate(row.name, 50)  }</a>
                const deploymentType = deploymentTypeLabels[row.deploymentType]
                const installType = row.deploymentType == "standard" ? row.autoDeploy ? "Auto" : "Manual" : installTypeLabels[row.installType]
                return { ...row, name, deploymentType, installType }
            },
        }
    }

    render() {
        let actions = <React.Fragment>
            { this.renderActionButton() }
            <a className="btn btn-default pull-right" href="#assign-groups" onClick={this.assignGroups}>Assign Groups</a>
        </React.Fragment>;

        const notFound = (
            <div className="centered alert alert-info" role="alert">
            No groups are currently assigned. Visit the <a href="#assign-groups" onClick={this.assignGroups}>Assign Groups</a> page to assign a new group.
            </div>
        );

        return (
            <React.Fragment>
                <div id="profile-assignments-table">
                    { this.props.renderTopSection(null, actions) }
                    { this.props.dataNotFound ? notFound : this.props.renderPagedTable(this.pagedTableProps()) }
                </div>
                {<AssignGroupsModal
                    appId={this.props.appId}
                    show={this.state.showModal}
                    onClose={() => this.setState({ showModal: false })}
                    onSuccess={() => {
                        this.props.readData()
                        this.setState({ showModal: false })
                    }}
                />}
            </React.Fragment>
        )
    }
}

export default pagedTableLayout(GroupsPagedTable, getAjaxClient, undefined, null);