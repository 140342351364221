import React from "react";
import PropTypes from 'prop-types';
import { devices as devicesClient } from '../../ajax_clients'
import { bindMethods } from "../../shared/functions";
import { visitURL } from "../../shared/query";
import RadioButtonGroup from "../forms/radio_button_group";

export default class Restart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notifyUser:         false,
            rebuildKernelCache: false,
            processing:         false
        }
        bindMethods(this);
    }

    toggleNotifyUser(option) {
        const notifyUser = option.value
        this.setState({ notifyUser })
    }

    toggleRebuildKernelCache(option) {
        const rebuildKernelCache = option.value;
        this.setState(({ rebuildKernelCache }))
    }

    visitDevicePage() {
        visitURL(AdminRoutes.adminDevicePath(this.props.deviceIds[0], {format: null}))
    }

    cancel(e) {
        e.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel()
        } else {
            this.visitDevicePage()
        }
    }

    submit(e) {
        e.preventDefault();
        const { notifyUser, rebuildKernelCache } = this.state
        this.setState({ processing: true })

        devicesClient.restart(this.props.deviceIds, notifyUser, rebuildKernelCache).then(() => {
            this.setState({ processing: false })
            if (this.props.onRestart) {
                this.props.onRestart();
            } else {
                this.visitDevicePage()
            }
        })
    }

    render() {
        let title;
        if (this.props.deviceName) {
            title = <h1>{ this.props.deviceName }&nbsp;<small>Restart Device</small></h1>
        } else {
            title = <h1>Restart Devices</h1>
        }

        const buttonText  = this.props.deviceName ? 'Restart Device' : 'Restart Devices'
        let submitClasses = ["btn", "btn-danger"]
        if (this.state.processing) {
            submitClasses.push("disabled")
        }
        submitClasses = submitClasses.join(' ')

        const notifyUserOptions         = {
            title:    'Notify User',
            onChange: this.toggleNotifyUser,
            value:    this.state.notifyUser,
            name:     'notify_user',
            hint: 'If a user is signed in, prompt them to optionally restart the device. Requires macOS 11.3 or later.',
            options:  [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ]
        }
        const rebuildKernelCacheOptions = {
            title:    'Rebuild Kernel Cache',
            onChange: this.toggleRebuildKernelCache,
            value:    this.state.rebuildKernelCache,
            name:     'rebuild_kernel_cache',
            hint:     'Rebuild the kernel cache during restart. Requires macOS 11 or later.',
            options:  [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ]
        }

        return <div className="restart-devices multi-select-panel">
            { title }
            <div className="well">
                <div className="alert alert-info">
                    <strong>Note:</strong> This will restart the selected devices.
                </div>

                <form className="simple_form form-horizontal">
                    { <RadioButtonGroup { ...notifyUserOptions } /> }
                    { <RadioButtonGroup { ...rebuildKernelCacheOptions } /> }

                    <div className="buttons">
                        <a href="#cancel" className="btn btn-default" onClick={ this.cancel }>Cancel</a>
                        <a href="#submit" className={ submitClasses } onClick={ this.submit }>{ buttonText }</a>
                    </div>
                </form>
            </div>
        </div>

    }
}

Restart.propTypes =
    {
        deviceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    }

