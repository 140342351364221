import React from 'react'

import { useCallback } from "react"
import {
  DialogContent,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material"

export default function MuiModal(props) {
  const {
    onClose,
    onCommit,
    titleText,
    children,
    buttonText = "Save",
    cancelButtonText = "Cancel",
  } = props

  const handleSubmit = useCallback(() => {
    onCommit()
  }, [
    onCommit,
    onClose,
  ])
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        variant="h6"
      >
        {titleText}
      </DialogTitle>

      <Grid item xs marginLeft={1} paddingLeft={1}>
          <DialogContent>
            {children}
          </DialogContent>

          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button onClick={onClose}>
                  {cancelButtonText}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit" onClick={handleSubmit}>
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
      </Grid>
    </Dialog>
  )
}
